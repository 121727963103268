import { Flex, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { FC, MouseEvent } from 'react';

import { Snippet } from '@/features/audio-player/types';
import type { CrowdFundingCampaignStatusEnum } from '@/shared/api/codegen/demuzo';

import { CampaignProgress } from '../../molecules/campaign-progress';
import { CardButtons } from '../../molecules/card-buttons';
import { CardInfo } from '../../molecules/card-info';
import { CardLabel } from '../../molecules/card-label';
import { Divider } from '../../molecules/divider';

type Props = {
  onToggleFavoriteFeature: (evt: MouseEvent<HTMLButtonElement>) => void;
  onPlay: () => void;
  onBuy: () => void;
  href: string;
  isFavorite: boolean;
  snippet?: Snippet;
  imageUrl: string;
  totalInvested: number;
  crowdFundingCampaignStatus: CrowdFundingCampaignStatusEnum;
  progressToStart: number;
  daysLeft: number;
  limit: number;
  progress: number;
  artists: {
    avatarUrl: string;
    name: string;
  }[];
  productName: string;
  genres: string;
  withBg?: boolean;
  size: 'lg' | 'md' | 'sm' | 'xs';
  rounded?: boolean;
  canBuy: boolean;
  sharePrice: number;
  isButtonDivider?: boolean;
  isFeatured?: boolean;
  withMaxWight?: boolean;
};

export const CampaignCard: FC<Props> = ({
  onToggleFavoriteFeature,
  crowdFundingCampaignStatus,
  progressToStart,
  onPlay,
  onBuy,
  href,
  isFavorite,
  imageUrl,
  snippet,
  totalInvested,
  daysLeft,
  limit,
  progress,
  artists,
  productName,
  genres,
  withBg = true,
  size = 'md',
  rounded = true,
  canBuy,
  sharePrice,
  isButtonDivider,
  isFeatured,
  withMaxWight,
}) => {
  return (
    <Flex
      bg={withBg ? 'background.bg-primary' : 'transparent'}
      rounded={rounded ? '20px' : undefined}
      overflow='hidden'
      direction='column'
      w='full'
      maxW={withMaxWight ? '290px' : undefined}
    >
      <CardLabel
        size={size}
        onPlay={onPlay}
        imageUrl={imageUrl}
        snippet={snippet}
        href={href}
      />
      <LinkBox>
        <Flex
          direction='column'
          p={size === 'lg' ? '20px' : '20px 0'}
          pb={0}
        >
          <CampaignProgress
            totalInvested={totalInvested}
            daysLeft={daysLeft}
            limit={limit}
            progress={progress}
            mt={0}
            rounded={false}
            crowdFundingCampaignStatus={crowdFundingCampaignStatus}
            progressToStart={progressToStart}
            progressVariant={'default'}
          />

          <LinkOverlay href={href}>
            <CardInfo
              size={size}
              m={{ base: '20px 0 0', lg: size === 'lg' ? '20px 0 0 ' : 0 }}
              artists={artists}
              productName={productName}
              genres={genres}
            />
          </LinkOverlay>

          {isButtonDivider ? <Divider mt='20px' /> : null}
        </Flex>
      </LinkBox>

      <Flex
        direction='column'
        p={
          size === 'lg' ? { base: '20px', lg: '40px 20px 20px 20px' } : '20px 0'
        }
        pb={0}
      >
        <CardButtons
          isFeatured={isFeatured}
          revertSharePrice
          sharePrice={sharePrice}
          m={!isButtonDivider ? '20px 0 0' : undefined}
          onToggleFavoriteFeature={onToggleFavoriteFeature}
          canBuy={canBuy}
          onBuy={onBuy}
          isFavorite={isFavorite}
        />
      </Flex>
    </Flex>
  );
};
