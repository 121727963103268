import { Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

import type { CrowdFundingCampaignStatusEnum } from '@/shared/api/codegen/demuzo';
import { formatCurrency } from '@/shared/utils/format-currency';

import { ProgressBar, ProgressBarVariant } from '../progress-bar/progress-bar';

type Props = {
  crowdFundingCampaignStatus: CrowdFundingCampaignStatusEnum;
  progressToStart: number;
  totalInvested: number;
  daysLeft: number;
  limit: number;
  progress: number;
  mt?: string | number;
  rounded?: boolean;
  progressVariant: 'small' | 'default';
};

const progressBarVariantMap: Record<
  CrowdFundingCampaignStatusEnum,
  ProgressBarVariant
> = {
  canceled: 'yellow',
  finished: 'yellow',
  in_progress: 'yellow',
  planned: 'gray',
  suspended: 'orange',
};

const getColorByStatus = (status: CrowdFundingCampaignStatusEnum) => {
  switch (status) {
    case 'finished':
      return 'text&icon.tx-accent';
    case 'suspended':
      return 'text&icon.tx-warning';
    default:
      return 'text&icon.tx-secondary';
  }
};

export const CampaignProgress: FC<Props> = ({
  crowdFundingCampaignStatus,
  progressToStart,
  totalInvested,
  daysLeft,
  limit,
  progress,
  progressVariant,
  rounded = true,
  mt = '40px',
}) => {
  const t = useTranslations();

  return (
    <Flex
      direction='column'
      gap='10px'
      mt={mt}
    >
      <ProgressBar
        height={progressVariant === 'small' ? 3 : 10}
        progress={
          crowdFundingCampaignStatus === 'planned' ? progressToStart : progress
        }
        variant={progressBarVariantMap[crowdFundingCampaignStatus]}
        rounded={rounded && progressVariant === 'default'}
      />
      <Flex
        justify='space-between'
        position='relative'
      >
        {crowdFundingCampaignStatus !== 'planned' &&
          progressVariant === 'default' && (
            <Flex
              direction='row'
              gap='8px'
            >
              <Text
                textStyle='text-regular-mono-xs'
                color={
                  crowdFundingCampaignStatus === 'canceled' ||
                  crowdFundingCampaignStatus === 'suspended' ||
                  totalInvested === 0
                    ? 'text&icon.tx-secondary'
                    : 'text&icon.tx-accent'
                }
              >
                {formatCurrency(totalInvested, true)}
              </Text>
              <Text
                textAlign='center'
                textStyle='text-regular-mono-xs'
                color='text&icon.tx-secondary'
              >
                {t('CampaignProgress.of', {
                  limit: formatCurrency(limit, true),
                })}
              </Text>
            </Flex>
          )}

        <Text
          textAlign={
            crowdFundingCampaignStatus === 'planned' ? 'left' : 'center'
          }
          textStyle='text-regular-mono-xs'
          color={getColorByStatus(crowdFundingCampaignStatus)}
        >
          {crowdFundingCampaignStatus === 'in_progress' &&
            t('CampaignProgress.daysToGo', { count: daysLeft })}
          {crowdFundingCampaignStatus === 'planned' &&
            t('CampaignProgress.daysBeforeStart', { count: daysLeft })}
          {crowdFundingCampaignStatus === 'suspended' &&
            t('CampaignProgress.suspended')}
          {crowdFundingCampaignStatus === 'canceled' &&
            t('CampaignProgress.notFunded')}
          {crowdFundingCampaignStatus === 'finished' &&
            t('CampaignProgress.funded')}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CampaignProgress;
