import { Box, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { FC } from 'react';

import { usePlayList, usePlayerControls } from '@/features/audio-player';
import { Snippet } from '@/features/audio-player/types';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconPause from '@ui-kit/icons/icon-pause';
import IconPlay from '@ui-kit/icons/icon-play';

import { cardLabelSizes } from './constants';

type Props = {
  onPlay?: () => void;
  imageUrl: string;
  snippet?: Snippet;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  m?: string | number;
  href: string;
};

export const CardLabel: FC<Props> = ({
  onPlay,
  snippet,
  imageUrl,
  href,
  size = 'md',
  m,
}) => {
  const { setPlayList } = usePlayList();
  const { play, pause, checkIsActiveTrack } = usePlayerControls();

  const handlePlay = () => {
    if (!snippet) {
      return;
    }

    setPlayList([snippet]);
    checkIsActiveTrack(snippet) ? pause() : play();
    if (onPlay) {
      onPlay();
    }
  };
  const isActive = snippet ? checkIsActiveTrack(snippet) : false;

  return (
    <Box pos='relative'>
      {snippet && (
        <IconButton
          onClick={handlePlay}
          aria-label={'play'}
          position='absolute'
          top='50%'
          left='50%'
          mt='-30px'
          ml='-30px'
          size='lg'
          variant='solid'
          colorScheme='white'
          zIndex='docked'
          icon={
            isActive ? (
              <IconPause
                w='26px'
                h='26px'
              />
            ) : (
              <IconPlay
                w='26px'
                h='26px'
              />
            )
          }
        />
      )}
      <LinkBox>
        <LinkOverlay href={href}>
          <Box
            w={'full'}
            h={cardLabelSizes[size].boxSize}
            m={m}
            position='relative'
            bgImage={imageUrl}
            bgSize='cover'
            bgPos='center'
            bgRepeat='no-repeat'
            rounded='5px'
            overflow='hidden'
          />
        </LinkOverlay>
      </LinkBox>
    </Box>
  );
};

export default CardLabel;
