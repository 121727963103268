import { Snippet } from '@/features/audio-player/types';
import type { Product } from '@/shared/api/codegen/demuzo';
import { convertToLargerUnit } from '@/shared/utils/convert-price';

export const mapApiProductToProduct = (
  product: Product,
): Omit<Product, 'snippets'> & { snippets: Snippet[] } => {
  return {
    ...product,
    crowdFundingCampaign: {
      ...product.crowdFundingCampaign,
      userInvested: product.crowdFundingCampaign.userInvested
        ? convertToLargerUnit(product.crowdFundingCampaign.userInvested)
        : null,
      userRevenue: product.crowdFundingCampaign.userRevenue
        ? convertToLargerUnit(product.crowdFundingCampaign.userRevenue)
        : null,
      successThreshold: convertToLargerUnit(
        product.crowdFundingCampaign.successThreshold,
      ),
      sharePrice: convertToLargerUnit(product.crowdFundingCampaign.sharePrice),
      totalInvested: convertToLargerUnit(
        product.crowdFundingCampaign.totalInvested,
      ),
    },
    snippets: product.snippets.map((snippet) => ({
      id: snippet.id,
      name: snippet.name,
      authors: product.artist.map((artist) => ({
        coverUrl: artist.avatarUrl,
        name: artist.name,
      })),
      coverUrl: product.snippetCoverUrl,
      tags: [product.primaryGenre, product.secondaryGenre],
      album: {
        coverUrl: product.artist.at(0)?.avatarUrl || '', // TODO change if use
        name: product.artist.map((item) => item.name).join(' x '),
      },
      url: snippet.url,
      mediaType: snippet.mediaType,
    })),
  };
};
