import { Box, BoxProps, ResponsiveValue, forwardRef } from '@chakra-ui/react';
import { ElementType, ReactNode } from 'react';

import PanelHeader from '../../atoms/panel-header/panel-header';
import { HeaderType } from '../../atoms/panel-header/types';

type Props = {
  header: string;
  rightAction?: ReactNode;
  headerType?: HeaderType;
  withPadding?: boolean;
  pHeader?: ResponsiveValue<number | (string & {})>;
  isVisible?: boolean;
  headerAs?: ElementType;
} & BoxProps;

export const Section = forwardRef<Props, 'div'>(
  (
    {
      children,
      header,
      rightAction,
      withPadding = true,
      headerType = 'h2',
      pHeader,
      headerAs,
      isVisible = true,
      ...props
    },
    ref,
  ) => {
    if (!isVisible) {
      return null;
    }

    return (
      <Box
        ref={ref}
        {...props}
      >
        <PanelHeader
          as={headerAs}
          headerType={headerType}
          rightElement={rightAction}
          withPadding={withPadding}
          pHeader={pHeader}
        >
          {header}
        </PanelHeader>

        {children}
      </Box>
    );
  },
);

export default Section;
